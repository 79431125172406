var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('FormGroup', {
    attrs: {
      "id": "name",
      "label": "Name",
      "error": _vm.validationErrors.name,
      "name": "Name"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "description",
      "label": "Description",
      "error": _vm.validationErrors.description,
      "description": "Describe what this report is used for"
    },
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "category",
      "label": "Category",
      "error": _vm.validationErrors.category,
      "description": "What category does this report belong to? (Optional)"
    },
    model: {
      value: _vm.form.category,
      callback: function ($$v) {
        _vm.$set(_vm.form, "category", $$v);
      },
      expression: "form.category"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "access",
      "label": "Access",
      "error": _vm.validationErrors.access,
      "type": "radio",
      "options": [{
        label: 'Just Me',
        value: 'user'
      }, {
        label: 'All Company Users',
        value: 'company'
      }],
      "description": "Who should have access to this report template?"
    },
    model: {
      value: _vm.form.access,
      callback: function ($$v) {
        _vm.$set(_vm.form, "access", $$v);
      },
      expression: "form.access"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }